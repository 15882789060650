export interface IFont {
  label: string;
  setting: any;
  min?: number;
  max?: number;
  experiment?: string;
}

export enum IListMobileScreenFromSettings {
  Schedule = 'Schedule',
  Overview = 'Overview',
}

export enum ISidebarMobileScreenFromSettings {
  List = 'List',
  Schedule = 'Schedule',
  Overview = 'Overview',
}

export interface IFontSection {
  label: string;
  items: IFont[];
  listMobileScreen?: IListMobileScreenFromSettings;
  sidebarMobileScreen?: ISidebarMobileScreenFromSettings;
  experiment?: string;
}
